import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  { path: '/', redirect: '/index' },
  {
    path: '/index',
    redirect: '/index/user',
    component: () => import('./pages/index.vue'),
    children: [
      { path: '/index/user', component: () => import('./pages/user.vue'), meta: { pname: '用户管理' } },
      { path: '/index/shebei', component: () => import('./pages/shebei.vue'), meta: { pname: '用户管理 / 设备详情', activePath: '/index/user' } },
      { path: '/index/order', component: () => import('./pages/order.vue'), meta: { pname: '订单管理' } },
      { path: '/index/pat', component: () => import('./pages/patient.vue'), meta: { pname: '患者管理' } },
      { path: '/index/dtlpat', component: () => import('./pages/patientDtl.vue'), meta: { pname: '患者详情' } },
      { path: '/index/vip', component: () => import('./pages/vip.vue'), meta: { pname: '会员管理' } },
      { path: '/index/fee', component: () => import('./pages/fee.vue'), meta: { pname: '会员费设置' } },
      { path: '/index/code', component: () => import('./pages/code.vue'), meta: { pname: '激活码设置' } },
      { path: '/index/scan', component: () => import('./pages/scan.vue'), meta: { pname: '扫描状态命名' } },
      { path: '/index/ad', component: () => import('./pages/ad.vue'), meta: { pname: '广告设置' } },
      { path: '/index/tiyan', component: () => import('./pages/tiyan.vue'), meta: { pname: '三天体验' } },
      { path: '/index/admin', component: () => import('./pages/admin.vue'), meta: { pname: '管理员管理' } },
      { path: '/index/banner', component: () => import('./pages/banner.vue'), meta: { pname: '轮播图管理' } },
      { path: '/index/about', component: () => import('./pages/about.vue'), meta: { pname: '关于我们管理' } }
    ]
  },
  { path: '/login', component: () => import('./pages/login.vue') }
];

export default createRouter({
  history: createWebHistory(),
  routes
});
