<style lang="less" scoped>
  .el-pagination {
    text-align: center;
    margin-top: 30px;
  }
  .table_cp {
    :deep(table) {
      th, td {
        text-align: center;
      }
      img {
        max-width: 300px;
        max-height: 300px;
      }
    }
  }
  
</style>

<template>
  <template v-if="showTable">
    <el-table class="table_cp" :data="tableData.data" @selection-change="selectionChange">
      <el-table-column v-if="!cfg.noCheck" type="selection" width="55"> </el-table-column>
      <template v-for="{ prop, label, html, fn } in showKeyData" :key="prop">

        <el-table-column v-if="fn" :prop="prop" :label="label">
          <template #default="{ row }">
            <div v-if="html" v-html="html(row)" @click="fn(row)"></div>
            <div v-else @click="fn(row)">{{ row[prop] }}</div>
          </template>
        </el-table-column>

        <el-table-column v-else-if="html" :prop="prop" :label="label">
          <template #default="{ row }">
            <div v-html="html(row)"></div>
          </template>
        </el-table-column>
        
        <el-table-column v-else :prop="prop" :label="label"></el-table-column>

      </template>
      <el-table-column v-if="operateData && operateData.length" fixed="right" label="操作">
        <template #default="{ row }">
          <!-- <slot name="operate" /> -->
          <template v-for="{ l, k, f, cls } in operateData" :key="k">
            <el-button type="text" @click="f(row)" :class="cls">{{ l }}</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="!cfg.noPage"
      @size-change="sizeChange"
      @current-change="pageChange"
      v-model:currentPage="tableData.cur"
      :page-size="tableData.size"
      :page-sizes="[10, 20, 50, 100]"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.total"
    >
    </el-pagination>
  </template>

  <div class="center" v-else>别着急，数据马上就来~</div>
</template>

<script>
  import { defineComponent, reactive, ref, watch } from 'vue'

  export default defineComponent({
    name: 'table_comp',
    props: ['cfg', 'opts'],
    emits: ['selection-change'],
    setup(props, { emit }) {
      let { showKeyData, operateData } = handleItems(props.cfg || {})

      let { search, tableData, pageChange, sizeChange } = useTableComp(props.cfg, props.opts)

      let { reload, showTable } = useReload()

      return {
        showTable,
        tableData,
        showKeyData,
        operateData,
        reload,
        search,
        selectionChange,
        pageChange,
        sizeChange
      }

      function selectionChange(v) {
        emit('selection-change', v)
      }
    }
  })

  // 表格相关交互
  function useTableComp(cfg, opts) {
    let { api, handleList } = cfg
    let isHandleListFn = typeof handleList === 'function'
    // 列表接口
    let listApi = api.list || ''

    // 列表数据翻页相关
    let tableData = reactive({
      data: [],
      size: 20,
      total: 0,
      cur: 1
    })

    // 外部触发请求改变 isSearch
    let isSearch = ref(false)
    watch(
      () => isSearch.value,
      v => {
        if (v) {
          tableData.cur = 1
          getList()
          isSearch.value = false
        }
      }
    )

    getList()

    return { tableData, getList, search, pageChange, sizeChange }

    // 暴漏给外部触发请求列表使用
    function search() {
      isSearch.value = true
    }

    // 列表请求
    function getList() {
      console.log(opts)
      tableData.data = []
      listApi({
        ...opts,
        pageNum: tableData.cur,
        pageSize: tableData.size
      }).then(r => {
        if (r) {
          let data = r.data
          let list
          if (data && Array.isArray(data)) list = data
          else list = data.list || []
          
          if (list.length && isHandleListFn) list = handleList(list)
          tableData.data = list
          tableData.total = (r.data && r.data.total) || 0
        }
      })
    }

    // 翻页相关
    function pageChange(p) {
      // if (tableData.cur != p) {
      //   tableData.cur = p
      //   getList()
      // }
      getList()
    }

    function sizeChange(v) {
      console.log(v)
      tableData.size = v
      tableData.cur = 1
      getList()
    }
  }

  // 不重新请求，直接重新加载表格相关
  function useReload() {
    let showTable = ref(true)
    // 数据变化重新初始化渲染表格
    function reload() {
      showTable.value = false
      showTable.value = true
    }
    return { reload, showTable }
  }

  // 表格展示字段处理
  function handleItems(cfg) {
    let { items, operates: opes } = cfg
    return {
      // 表头数据
      showKeyData: Object.keys(items || {}).map(k => {
        let { l: label } = items[k]
        return { ...items[k], prop: k, label }
      }),
      // 操作数据
      operateData: Object.keys(opes || {}).map(k => {
        return { k, ...opes[k] } // { l, k, f, cls }
      })
    }
  }
</script>
