import { ElMessageBox, ElMessage } from 'element-plus';

let tipMap = { 1: 'success', 2: 'error', 3: 'info' };

export let tip = (str, tp) => {
  ElMessage({
    showClose: true,
    message: str,
    type: tp && tipMap[tp] ? tipMap[tp] : 'warning'
  });
};

export const trim = str => (str || '').trim();

export function trimObjK(obj) {
  Object.keys(obj || {}).forEach(k => {
    let v = obj[k];
    if (typeof v === 'string') obj[k] = trim(v);
  });
}

// token
export const Token = {};
Object.defineProperty(Token, 't', {
  get() {
    return localStorage.getItem('uToken');
  },
  set(v) {
    localStorage.setItem('uToken', v);
  }
});

export function getToken() {
  return Token.t;
}

export function setToken(v) {
  Token.t = v;
}

export function confirm(msg, successCb, cancelCb) {
  if (msg === '确认删除?') msg = '数据删除后不可恢复，确定要删除吗?';
  ElMessageBox.confirm(msg, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      if (typeof successCb === 'function') successCb();
      else if (typeof successCb === 'string') {
        tip(successCb, 1);
      }
    })
    .catch(() => {
      if (typeof cancelCb === 'function') cancelCb();
      else if (typeof cancelCb === 'string') {
        tip(cancelCb, 3);
      }
    });
}

// 响应式数据对象 赋值新对象会失去原来的响应式以及引用，操作原对象引用处理
// 删除原数据中新数据不存在的属性，合并新对象属性值到原数据
// target 不传或者为空对象{} 则表示清空数据对象的所有属性，清空为空对象 {}
export function objToTarget(data, target) {
  let dKs = Object.keys(data);
  let tKs = Object.keys(target || {});
  let oldKs = dKs.filter(k => tKs.indexOf(k) === -1);
  oldKs.forEach(k => delete data[k]); // 删除新对象不存在的老属性
  Object.assign(data, target); // 合并新数据到老数据上
}

export function saveStream(stream, name, fileType) {
  // let blob = new Blob([stream], { type: 'application/octet-stream' });
  // let blob = new Blob([stream]);
  fileType = 'application/' + (fileType || 'vnd.ms-excel')
  console.log('fileType ', fileType)
  let blob = new Blob([stream], { type: fileType });
  let a = document.createElement('a');
  let url = URL.createObjectURL(blob);
  a.href = url
  console.log(url)
  a.download = name;
  a.click();
  URL.revokeObjectURL(blob);
}

export function isBlankObj(data) {
  return data && Object.keys(data).length === 0;
}

export function objParamStr (obj) {
  let keys = Object.keys(obj || {})
  if (!keys.length) return ''
  let paramStr = keys.reduce((res, k) => res + `${k}=${obj[k] + ''}`, '?')
  // return paramStr === '?' ? '' : paramStr
  return paramStr
}
