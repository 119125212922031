import { createApp } from 'vue'
import Layout from './Layout.vue'
import components from './init/components.js'
import router from './router.js'

import './base.less'

const app = createApp(Layout)

app.use(router)
app.use(components) // 全局组件
app.mount('#app')
