<template>
  <el-form :inline="true" class="demo-form-inline">
    <el-form-item label="" width="300px">
      <el-input v-model="data" :placeholder="pl"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submit">查询</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { ref } from 'vue';
  import { trim } from '@/fn';

  export default {
    props: ['placeholder'],
    emits: ['ok', 'cancel'],
    setup(props, { emit }) {
      let data = ref('');
      let pl = props.placeholder;

      return { data, submit, pl };

      function submit() {
        emit('ok', trim(data.value));
      }

      function cancel() {
        emit('cancel', data);
      }
    }
  };
</script>

<style scoped>
  img {
    width: 200px;
  }
</style>
