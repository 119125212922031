import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import Table from '../components/Table.vue'
import Form from '../components/Form.vue'
import InputBtn from '../components/InputBtn.vue'

export default app => {
    app.use(ElementPlus, { locale: zhCn, size: 'small', zIndex: 3000 })
    app.component('Table', Table)
    app.component('Form', Form)
    app.component('InputBtn', InputBtn)
}