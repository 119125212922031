<template>
  <div ref="dom"></div>
</template>

<script>
  import E from 'wangeditor'
  import { watch, ref, computed, onMounted } from 'vue'
  export default {
    name: 'WangEditor',
    props: ['modelValue'],
    emits: ['update:modelValue', 'err'],
    setup(props, ctx) {
      let { emit } = ctx
      let dom = ref(null)

      onMounted(() => {
        let editor = new E(dom.value)

        // 初始化赋值
        editor.customConfig = {
          uploadImgServer: '/api/upload',  // 上传图片到服务器
          uploadFileName: 'file',
          uploadImgHooks: {
            before: function (xhr, editor, files) {
                // 图片上传之前触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，files 是选择的图片文件
                
                // 如果返回的结果是 {prevent: true, msg: 'xxxx'} 则表示用户放弃上传
                // return {
                //     prevent: true,
                //     msg: '放弃上传'
                // }
            },
            success: function (xhr, editor, result) {
                // 图片上传并返回结果，图片插入成功之后触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
            },
            fail: function (xhr, editor, result) {
              console.log(1)
                // 图片上传并返回结果，但图片插入错误时触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
            },
            error: function (xhr, editor) {
              console.log(2)
                // 图片上传出错时触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
            },
            timeout: function (xhr, editor) {
                // 图片上传超时时触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
            },

            // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
            // （但是，服务器端返回的必须是一个 JSON 格式字符串！！！否则会报错）
            customInsert: function (insertImg, result, editor) {
              // result 必须是一个 JSON 格式字符串！！！否则报错
              // console.log(result)
              // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
              // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果
              // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
              if (result.code == 1) insertImg(result.data.url)
            }
          },
          // 改变编辑器触发
          onchange: (html) => {
            console.log(html)
            emit('update:modelValue', html)
          }
        }

        // 生成编辑器
        editor.create()
        // 初始化赋值
        editor.txt.html(props.modelValue || '')
      })
      
      return { dom }
    }
  }
</script>

<style scoped>
</style>