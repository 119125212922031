<template>
  <el-form label-width="100px">
    <template v-for="{ l, k, dis, upload, editor } in items" :key="k">
      <el-form-item v-if="upload" :label="l">
        <Upload v-model="form[k]" />
      </el-form-item>

      <el-form-item v-else-if="editor" :label="l">
        <Editor v-model="form[k]" />
      </el-form-item>

      <el-form-item v-else :label="l">
        <el-input v-model="form[k]" :disabled="!!dis"></el-input>
      </el-form-item>
    </template>
    
    <el-form-item>
      <el-button @click="ok">确定</el-button>
      <el-button @click="cancel">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { reactive, watch, watchEffect } from 'vue';
  import { trimObjK, objToTarget } from '@/fn';
  import Upload from './Upload.vue'
  import Editor from './Editor.vue'
  export default {
    name: 'form_comp',
    props: ['init', 'data', 'show'],
    emits: ['cancel', 'ok'],
    components: { Upload, Editor },
    setup(props, { emit }) {
      let form = reactive({});

      let pItems = props.init || {};
      let pItemks = Object.keys(pItems);

      let items = pItemks.map(k => {
        form[k] = pItems[k].v || '';
        return { k, ...pItems[k] };
      });

      let initObj = pItemks.reduce((res, k) => {
        res[k] = pItems[k].v || '';
        return res;
      }, {});

      objToTarget(form, Object.assign({}, { ...initObj, ...(props.data || {}) }));

      watch(props.data, v => {
        objToTarget(form, Object.assign({}, { ...initObj, ...(v || {}) }));
      })

      // 处理表单展示隐藏时 form 对象的属性
      watchEffect(_ => {
        let v = props.show;
        if (v) {
          // 表单展示时 props.data initObj 合并的属性
          // initObj，props.data 不能作为第一个对象，会被污染，第一个放空对象
          // objToTarget(form, Object.assign({}, { ...initObj, ...(props.data || {}) }));
          objToTarget(form, { ...initObj, ...(props.data || {}) });
        } else {
          // 表单隐藏时 初始化为 initObj 属性对象
          initForm();
        }
      });

      return { initObj, form, items, ok, cancel };

      // 初始化 form 对象
      function initForm() {
        objToTarget(form, initObj);
      }

      function ok() {
        trimObjK(form);
        emit('ok', { ...form });
      }

      function cancel() {
        emit('cancel', form);
      }
    }
  };
</script>

<style scoped>
  img {
    width: 200px;
  }
</style>
