<template>
  <div class="layout-app">
    <router-view></router-view>
  </div>
</template>

<script>
  import { ref } from 'vue';
  export default {
    setup() {},
    data() {
      return {};
    }
  };
</script>

<style scoped>
  img {
    width: 200px;
  }
</style>
